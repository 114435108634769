import React, { useState, useCallback } from "react";
import type { IconProps } from "constants/IconConstants";
import { IconWrapper } from "constants/IconConstants";
import { Popover, Position } from "@blueprintjs/core";
import Entity, { EntityClassNames } from "pages/Editor/Explorer/Entity";
import { datasourceTableIcon } from "pages/Editor/Explorer/ExplorerIcons";
import styled from "styled-components";
import QueryTemplates from "./QueryTemplates";
import DatasourceField from "./DatasourceField";
import type { Datasource } from "entities/Datasource";
import { Colors } from "constants/Colors";
import { useCloseMenuOnScroll } from "@appsmith/pages/Editor/Explorer/hooks";
import { SIDEBAR_ID } from "constants/Explorer";
import { hasCreateDatasourceActionPermission } from "@appsmith/utils/permissionHelpers";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "@appsmith/reducers";
import { getDatasource } from "@appsmith/selectors/entitiesSelector";
import { createNewQueryAction } from "actions/apiPaneActions";
import {
  getPagePermissions,
  getCurrentPageId,
} from "selectors/editorSelectors";
import { importSvg } from "design-system-old";
import type { EventLocation } from "@appsmith/utils/analyticsUtilTypes";
import { Button } from "design-system";
import { DatasourceStructureContext } from "entities/Datasource";
const LightningIcon = importSvg(
  async () => import("assets/icons/control/lightning.svg"),
);

const StyledEntity = styled(Entity)`
  & > div {
    grid-template-columns: 20px auto 1fr auto auto;
  }
`;

interface DatasourceStructureProps {
  dbStructure: any;
  step: number;
  datasourceId: string;
  plugin?: any;
}

export function DatasourceStructureOfConfig(props: DatasourceStructureProps) {
  const dispatch = useDispatch();
  const actions = useSelector((state: AppState) => state.entities.actions);
  const currentPageId = useSelector(getCurrentPageId);
  const { plugin } = props;
  const pluginType = plugin?.type;
  const dbStructure = props.dbStructure;
  const iconProps: IconProps = {
    width: 12,
    height: 12,
  };
  const [active, setActive] = useState(false);
  useCloseMenuOnScroll(SIDEBAR_ID, active, () => setActive(false));

  const datasource: any = useSelector((state: AppState) =>
    getDatasource(state, props.datasourceId),
  );

  const datasourcePermissions = datasource?.userPermissions || [];
  const pagePermissions = useSelector(getPagePermissions);

  const canCreateDatasourceActions = hasCreateDatasourceActionPermission([
    ...datasourcePermissions,
    ...pagePermissions,
  ]);

  const createQueryAction = useCallback(
    (e, field) => {
      e?.stopPropagation();
      if (currentPageId) {
        if (datasource) {
          dispatch(
            createNewQueryAction(
              currentPageId,
              props.eventFrom as EventLocation,
              datasource?.id,
              { ...field },
            ),
          );
        }
      }
    },
    [dispatch, actions, currentPageId, datasource, pluginType],
  );

  const lightningMenu: any = canCreateDatasourceActions
    ? (field: any) => (
        <Button
          className={`button-icon t--template-menu-trigger ${EntityClassNames.CONTEXT_MENU}`}
          isIconButton
          kind="tertiary"
          onClick={(e) => {
            setActive(true);
            createQueryAction(e, { ...field });
          }}
          startIcon={
            props.context !== DatasourceStructureContext.EXPLORER
              ? "add-line"
              : "increase-control-v2"
          }
        />
      )
    : null;

  // if (dbStructure.templates) templateMenu = lightningMenu;
  const columnsAndKeys = dbStructure.definitions || [];
  return (
    <Entity
      action={() => canCreateDatasourceActions && setActive(!active)}
      active={active}
      className={`datasourceStructure${
        props.context !== DatasourceStructureContext.EXPLORER &&
        `-${props.context}`
      }`}
      contextMenu={null}
      entityId={`${props.datasourceId}-${dbStructure.title}-${props.context}`}
      forceExpand={props.forceExpand}
      icon={datasourceTableIcon}
      isDefaultExpanded={props?.isDefaultOpen}
      name={dbStructure.title}
      step={props.step}
    >
      {columnsAndKeys.map((field, index) => {
        return (
          <DatasourceField
            extra={lightningMenu(field)}
            field={field}
            key={`${field.name}${index}`}
            step={props.step + 1}
          />
        );
      })}
    </Entity>
  );
}

export default DatasourceStructureOfConfig;
